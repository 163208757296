'use strict';

jQuery(document).ready(function ($) {
  var modal = $('#myModal');
  var button = $('.head__mobile-hamburger');
  var selection = $('.selection');
  var close = $('.close-btn');
  var refresh = $('.sermons-prayers__refresh');
  var playButtons = $('.sermons-prayers__play-button');
  var pauseButtons = $('.sermons-prayers__pause-button');

  //bring modal up
  button.click(function () {
    modal.show();
  });

  close.click(function () {
    modal.hide();
  });

  //end of open modal


  // close the modal when nav is clicked
  selection.click(function () {
    modal.hide();
  });

  //end of close modal


  //refresh content in Sermons and Prayers section
  refresh.click(function () {
    window.location.reload();
  });
  //end refresh of content


  //pause and play of audio
  playButtons.click( function(e) {
    var play = $(e.target);
    var sermon = play.parent().parent();
    var pause = sermon.find('.sermons-prayers__pause-button');
    var audio = sermon.find('audio')[0];

    audio.play();
    play.hide();
    pause.show();
  });

  pauseButtons.click( function(e) {
    var pause = $(e.target);
    var sermon = pause.parent().parent();
    var play = sermon.find('.sermons-prayers__play-button');
    var audio = sermon.find('audio')[0];

    audio.pause();
    pause.hide();
    play.show();
  });
  //end of pause and play of audio



  //when you scroll to a certain spot, the nav will be fixed
  if (window.screen.width >= 1200) {
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 600) {
        $('#header_nav').addClass('fixed');
      } else {
        $('#header_nav').removeClass('fixed').css('top', '600');
      }
    });
  }

  if (window.screen.width >= 1024) {
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 465) {
        $('#header_nav').addClass('fixed');
      } else {
        $('#header_nav').removeClass('fixed').css('top', '465');
      }
    });
  }

  if (window.screen.width >= 960) {
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 375) {
        $('#header_nav').addClass('fixed');
      } else {
        $('#header_nav').removeClass('fixed').css('top', '375');
      }
    });

    // override anchor scrolling to account for sticky nav
    $('.head__navigation-options, .head__place-order').on('click', function(e) {
      e.preventDefault();
      var hash = $(this).attr('href');
      var scrollTo = $(hash);
      $('html,body').animate({
        scrollTop: scrollTo.offset().top - $('.head__sticky-nav').height()
      }, 'slow');
      window.location.hash = hash;
    });

  }

  //end scroll section




  // sets the grid of the masonry for different screen sizes
  if (window.screen.width >= 768) {
    $('.grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: 20,
      gutter: 20
    });
  }

  if (window.screen.width >= 960) {
    $('.grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: 20,
      gutter: 80
    });
  }

  if (window.screen.width >= 1160) {
    $('.grid').masonry({
      itemSelector: '.grid-item',
      columnWidth: 20,
      gutter: 50
    });
  }

  //end masonry grid


});
