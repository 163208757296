'use strict';

jQuery(document).ready(function($) {
  var columnCount;
  var columnWidth;
  var marginLeft;
  var activeColumn;
  init();

  $(window).resize(debounce(function() {
    init();
  }, 250));

  $('.prev-button').click(function (e) {
    if (activeColumn === 0) {
      return;
    }
    activeColumn -= 1;
    marginLeft += columnWidth;
    slideContent(marginLeft);
  });

  $('.next-button').click(function (e) {
    activeColumn += 1;
    marginLeft -= columnWidth;
    slideContent(marginLeft);
  });

  function init() {
    activeColumn = 0;
    marginLeft = 0;
    slideContent(marginLeft);

    columnCount = parseFloat($('.philosophy__content').css('column-count') ||
      $('.philosophy__content').css('-webkit-column-count') ||
      $('.philosophy__content').css('-moz-column-count') ||
      0);

    var columnGap = parseFloat($('.philosophy__content').css('column-gap') ||
      $('.philosophy__body').css('-webkit-column-gap') ||
      $('.philosophy__body').css('-moz-column-gap') ||
      0);

    columnWidth = $('.philosophy__body').width() + columnGap;
  }

  function slideContent(marginLeft) {
    $('.philosophy__content').animate({
      marginLeft: marginLeft + 'px'
    }, 'slow');
  }

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }
});
